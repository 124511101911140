import React from 'react'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import Share from '../components/share/sharing'
import {metadata} from '../../config'
import loadable from '@loadable/component'
import {
  adDisplayDelay,
  delayMultiplicationFactor,
  googleScriptLoadingDelay,
} from '../utils/articleUtil'
import {orderBy as _orderBy} from 'lodash'
import {isMobile} from 'react-device-detect'
import Helmet from 'react-helmet'
import consts from '../constants/consts'
import Navigate from '../components/navigate'
import CategoryTitleBordered from '../components/helpers/categoryTitleBordered'
import ReactHtmlParser, {convertNodeToElement} from 'react-html-parser'
import {ComparisonWidget} from '@cg-squad/ui-components'
import {GatsbyImage} from 'gatsby-plugin-image'
import {articlePageAds} from '../utils/adUnits'
import {URL} from '../constants/urls'
import { addTimeout } from '../utils/timeManager'

const ArticlePopularPosts = loadable(() => import('../components/popularPosts/articlePopular'))
const AuthorDetails = loadable(() => import('./authorDetails'))
const WhatNext = loadable(() => import('../components/whatNext'))
/*const WhatNextMobile = loadable(() => import('../components/whatNext/mobileRecommended'))*/

class Article extends React.Component {

  constructor (props) {
    super(props)
    this.data = props.pageContext.article
    this.url = `${metadata.url}${props.pageContext.url}`
    this.canonicalUrl = `${metadata.url}${props.pageContext.canonicalUrl}`
    this.categories = _orderBy(this.data.category, ['position'], ['asc'])
    this.subCategory = this.categories.find(item => item.treeParent !== null)
    this.longAdCount = 0
    this.lastIndexOfPTag = 0

    this.getRecommendedPosts()
    this.state = {
      isSubscribeOpen: false,
      popularPosts: this.recommendedPosts
    }

    this.schemaArticle = {
      '@context': 'https://schema.org',
      '@type': this.data.json && this.data.json.faq ? 'FAQPage' : 'Article',
      author: {
        '@type': 'Person',
        name: this.data.author.name,
        url: `${metadata.url}/authors/${this.data.author.slug}`
      },
      'publisher': {
        '@type': 'Organization',
        'name': metadata.defaultTitle,
        'logo': {
          '@type': 'ImageObject',
          'url': `${metadata.url}/images/logos/logo.jpeg`
        }
      },
      datePublished: this.data.publishedAt,
      description: this.data.excerpt,
      headline: this.data.seo.title,
      url: this.url,
      name: this.data.seo.title,
      wordCount: this.props.pageContext.wordCount,
      articleSection: this.subCategory.title
    }
    if (this.data.seo.image) {
      this.schemaArticle.image = {
        '@type': 'ImageObject',
        url: this.data.seo.image.url
      }
    }
    if (this.data.json && this.data.json.faq) {
      this.schemaArticle.mainEntity = []
      this.data.json.faq.forEach(faq => {
        this.schemaArticle.mainEntity.push({
          '@type': 'Question',
          'name': faq.title,
          'acceptedAnswer': {
            '@type': 'Answer',
            'text': faq.text
          }
        })
      })
    } else {
      this.schemaArticle.mainEntityOfPage = this.url
    }
  }

  /*getRecommendedArticleFromServer () {
    if (!isBrowser()) {
      return
    }
    const category = this.subCategory.treeParent.slug
    const subCategory = this.subCategory.slug
    const _ga = getCookie('_ga')
    if (!_ga || _ga === '') {
      return
    }
    fetch(
      `${URL.SERVER}/articles/recommended?category=${category}&subCategory=${subCategory}&domain=healthtimes.co.uk&article=/${category}/${subCategory}/${this.data.slug}&_ga=${_ga}`,
      {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(resp => resp.json())
      .then(res => {
        let articles = (res || []).map(item => {
          return {
            author: {
              name: item.authorData?.name || item.author,
              slug: item.authorData?.url?.split('/')
                .pop()
            },
            categorySlug: item.category,
            subCategorySlug: item.sub_category,
            heading: item.page_title?.split('|')[0].trim(),
            cover: {
              url: `${item.image ? `${item.image.replace('?auto=format', '')}?auto=compress,format&crop=focalpoint&fit=crop&w=300&h=300` : null}`,
              noModify: true
            },
            completePath: item.page_path,
            slug: item.page_path.split('/')
              .pop()
          }
        })
        articles = _uniqBy(articles.concat(this.state.popularPosts), 'slug')
        this.setState({
          popularPosts: articles
        })
      })
  }*/

  getRecommendedPosts () {
    //this.getRecommendedArticleFromServer()
    this.recommendedPosts = []

    const slugs = []
    let posts = []
    const relatedArticlesObject = this.props.pageContext.relatedArticles
    posts = posts
      .concat(relatedArticlesObject.ai || [])
      .concat(relatedArticlesObject.tags10 || [])
      .concat(relatedArticlesObject.tags9 || [])
      .concat(relatedArticlesObject.tags8 || [])
      .concat(relatedArticlesObject.tags7 || [])
      .concat(relatedArticlesObject.tags6 || [])
      .concat(relatedArticlesObject.tags5 || [])
      .concat(relatedArticlesObject.tags4 || [])
      .concat(relatedArticlesObject.tags3 || [])
      .concat(relatedArticlesObject.tags2 || [])
      .concat(relatedArticlesObject.tags1 || [])
      .concat(relatedArticlesObject.sameCategory || [])
      .concat(relatedArticlesObject.random || [])

    for (let i = 0; i < posts.length; i++) {
      const post = posts[i]
      if (!slugs.includes(post.slug)) {
        this.recommendedPosts.push(post)
        slugs.push(post.slug)
      }
    }
    this.popularPosts = [...this.recommendedPosts]
  }

  replace_nth (s, f, r, n) {
    // From the given string s, replace f with r of nth occurrence
    return s.replace(RegExp('^(?:.*?' + f + '){' + n + '}'), x => x.replace(RegExp(f + '$'), r))
  };

  getContent () {
    let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '')
    content = content.replace(/ aria-level="1"/g, '')
    content = content.replace(/ style="font-weight: 400;"/g, '')
    content = content.replace(/ padding-left:40px/g, '')

    let contentArray = []
    if (this.props.pageContext.pageNumber) {
      contentArray = this.replace_nth(content, '<h2>', '$pension$<h2>', this.props.pageContext.pageNumber)
        .split('$pension$')
      contentArray = this.replace_nth(contentArray[1], '<h2>', '$pension$<h2>', 2)
        .split('$pension$')
      content = contentArray[0]
    } else {
      contentArray = content.split('<h2>')
      content = contentArray[0]
    }

    content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, 'data-src="https://www.youtube.com/embed')

    return content
  }

  transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'table') {
      node.attribs.style = 'margin-left: auto; margin-right: auto;' + (node.attribs.style ? node.attribs.style : '')
      return convertNodeToElement(node, index, null)
    }
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class === 'comparison-widget-placeholder') {
      if (this.data.compareTable && this.data.compareTable.comparisonData) {
        return <ComparisonWidget data={this.data.compareTable.comparisonData}/>
      }
      return null
    }
    if (node.type === 'tag' && node.name === 'p' && (!node.parent || node.parent.name === 'div') && !(node.attribs.class || '').includes('affiliate-link')) {
      ++this.lastIndexOfPTag
      let customElements = []
      if (this.lastIndexOfPTag === 2) {
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id="ht_article_incontent1"/>
        </div>)
      }
      if (this.lastIndexOfPTag === 7) {
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id="ht_article_incontent2"/>
        </div>)
      }
      if (this.lastIndexOfPTag === 12) {
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id="ht_article_incontent3"/>
        </div>)
      }
      if (this.lastIndexOfPTag >= 17 && this.lastIndexOfPTag % 5 === 2 && this.longAdCount < 4) {
        ++this.longAdCount
        customElements.push(<div className="ad-container">
          <div className="advertisement-text">Advertisement</div>
          <div id={`ht_article_incontent-0${this.longAdCount}`} className="long-ad-container"/>
        </div>)
      }
      if (isMobile && this.lastIndexOfPTag === 5) {
        customElements.push(<WhatNext
          recommendedPosts={this.state.popularPosts.splice(0, isMobile ? 2 : 4)}/>)
      }

      if (customElements.length) {
        return <>
          {convertNodeToElement(node, index, null)}
          {customElements.map(element => element)}
        </>
      }
    }
  }

  componentDidMount () {
    addTimeout(() => {
      const articleDom = document.getElementById('article-content')
      const children = articleDom.children
      const secondLastElement = children[children.length - 2]
      if (secondLastElement?.tagName === 'DIV' && secondLastElement?.className === 'ad-container') {
        if (this.longAdCount > 0) {
          this.longAdCount--
        }
        //secondLastElement.remove();
        secondLastElement.style.display = 'none'
      }
      articlePageAds([
          {key: 'category', value: this.subCategory.treeParent.title},
          {key: 'subcategory', value: this.subCategory.slug},
          {key: 'slug', value: this.data.slug}
        ],
        this.longAdCount)
    }, adDisplayDelay() * delayMultiplicationFactor(isMobile))

    const youtubeVideoDisplay = () => {
      const vidDefer = document.getElementsByTagName('iframe')
      for (let i = 0; i < vidDefer.length; i++) {
        if (vidDefer[i].getAttribute('data-src')) {
          vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'))
        }
      }
    }

    if (googleScriptLoadingDelay() === consts.SCRIPT_LOADING_TIME_WAIT) {
      window.onload = () => {
        addTimeout(() => {
          youtubeVideoDisplay()
        }, consts.SCRIPT_LOADING_TIME_WAIT * delayMultiplicationFactor(isMobile))
      }
    } else {
      addTimeout(() => {
        youtubeVideoDisplay()
      }, 1000 * delayMultiplicationFactor(isMobile))
    }
  }

  render () {
    this.lastIndexOfPTag = 0
    return <Layout relativePath={this.props?.pageContext?.url}>
      <Helmet>
        {/*{!this.props.pageContext.pageNumber && <link rel="preload" as="image" href={this.data.cover.responsiveImage.src}
                      imagesrcset={this.data.cover.responsiveImage.webpSrcSet} imagesizes={this.data.cover.responsiveImage.sizes}/>}*/}
        <meta name="keywords" content={this.data.tags}/>
        <script type="application/ld+json">{JSON.stringify(this.schemaArticle)}</script>
        <link rel="canonical" href={this.url}/>
      </Helmet>
      <Basic seo={this.data.seo}/>
      <Twitter seo={this.data.seo} url={this.url}/>
      <Facebook seo={this.data.seo} url={this.url}/>
      <main className="wrapper main-data-container article-page">
        <div className={'w-full lg:flex'}>
          <article className="lg:w-[calc(100%-305px)]">
            <CategoryTitleBordered className={'uppercase'}
                                   parentLink={`/${this.subCategory.treeParent.slug}`}
                                   parentTitle={this.subCategory.treeParent.title}
                                   link={`/${this.subCategory.treeParent.slug}/${this.subCategory.slug}`}
                                   title={this.subCategory.title}/>
            <header className="font-arial">
              {this.props.pageContext.pageNumber > 0 && <h1
                className="text-2xl lg:text-4xl font-helvetica font-semibold mt-3 lg:mt-0 mb-0 lg:mb-3 leading-tight">{this.data.heading}</h1>}
              {!this.props.pageContext.pageNumber &&
              <div className={'flex flex-col lg:flex-row mb-4 lg:mb-8'}>
                <div className={'flex-grow-2 lg:mr-4'}>
                  <GatsbyImage loading="eager" className={'h-full w-full lg:w-[383px]'}
                               alt={this.data.heading} image={this.data.cover.gatsbyImageData}/>
                </div>

                <div className={'flex-grow-3'}>
                  <h1
                    className="text-2xl lg:text-4xl font-helvetica font-semibold mt-3 lg:mt-0 mb-0 leading-tight">{this.data.heading}</h1>
                  <div className="flex items-center text-sm text-black text-opacity-50"
                       data-datocms-noindex>
                    <div>
                      By <Navigate
                      href={`/authors/${this.data.author.slug}`}
                      className={'text-denim article-author-name'}>{this.data.author.name}</Navigate>
                    </div>
                    {/*<time className={"ml-2"}>{formatDate(this.data.publishedAt, 'mmmm dd, yyyy')}</time>*/}
                    <span>&nbsp;·&nbsp;{this.props.pageContext.readingTime} min read</span>
                    {this.data.isSponsored && <span>&nbsp;·&nbsp;sponsored</span>}
                  </div>
                  {this.data.excerptHtml
                    ? <div
                      className="font-helvetica text-base lg:text-lg leading-snug mb-0 mt-4 article-excerpt"
                      dangerouslySetInnerHTML={{__html: this.data.excerptHtml}}/>
                    : <p
                      className="font-helvetica text-base lg:text-lg leading-snug mb-0 mt-4">{this.data.excerpt}</p>}
                </div>
              </div>}

              <div className="lg:hidden"><Share/></div>
            </header>
            <main doc-container="main" className="relative">
              <div id="article-content">
                {ReactHtmlParser(this.getContent(), {transform: this.transform})}
              </div>
              {this.props.pageContext.totalPages && this.props.pageContext.pageNumber < this.props.pageContext.totalPages &&
              <div>Read more on the next page...</div>}
              {!isMobile && <div className={'absolute'} style={{top: 0, left: '-65px'}}>
                <Share/>
              </div>}
            </main>
            {/*<div className="ad-container">
                        <div className="advertisement-text">Advertisement</div>
                        <div id="ht_article_endcontent"/>
                      </div>*/}
            {this.props.pageContext.totalPages && this.props.pageContext.pageNumber < this.props.pageContext.totalPages &&
            <Navigate
              className={'w-full inline-block p-2 text-center bg-denim font-bold text-white text-2xl rounded-sm mt-2'}
              href={`/${this.props.pageContext.url}/${this.props.pageContext.pageNumber + 1}`}>Next&nbsp;&nbsp;&#10095;</Navigate>}
            {isMobile && <WhatNext recommendedPosts={this.state.popularPosts}/>}
            <div className={'my-4'}>
              <AuthorDetails author={this.data.author}
                             deferImage={true} data-datocms-noindex
                             bioClass={'text-sm leading-relaxed font-light'}/>
            </div>
          </article>
          {!isMobile && <ArticlePopularPosts articles={this.state.popularPosts}/>}
        </div>
      </main>
    </Layout>
  }
}

export default Article
